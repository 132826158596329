import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import {
  validateSIN,
  validateAmount,
} from "Utils/EmploymentAndIncomeUtilities";

const VALIDATIONSLIST = {
  SIGNIN: {
    username: {
      fieldName: STRINGS.SIGNIN.FORM.USERNAMELABEL,
      required: true,
      pattern: AppConstants.SIGNIN.EMAILPATTERN,
    },
    password: {
      fieldName: STRINGS.SIGNIN.FORM.PASSWORDLABEL,
      required: true,
    },
    bankId_PAN: {
      fieldName: STRINGS.SIGNIN.FORM.BANK_ID_LABEL_PAN,
      required: true,
    },
    bankId_MEMBER_NO: {
      fieldName: STRINGS.SIGNIN.FORM.BANK_ID_LABEL_MEMBER_NO,
      required: true,
    },
  },
  REGISTRATION: {
    email: {
      fieldName: STRINGS.REGISTRATION.FORM.EMAILLABEL,
      required: true,
      pattern: AppConstants.REGISTRATION.EMAILPATTERN,
    },
    mobileno: {
      fieldName: STRINGS.REGISTRATION.FORM.MOBILENOLABEL,
      required: true,
      pattern: AppConstants.REGISTRATION.MOBILENOPATTERN,
    },
    bankId_PAN: {
      fieldName: STRINGS.REGISTRATION.FORM.BANK_ID_LABEL_PAN,
      required: true,
    },
    bankId_MEMBER_NO: {
      fieldName: STRINGS.REGISTRATION.FORM.BANK_ID_LABEL_MEMBER_NO,
      required: true,
    },
    termscondition: {
      required: true,
    },
  },
  VERIFICATIONCODE: {
    verificationcode: {
      fieldName: STRINGS.VERIFICATIONCODE.FORM.VERIFICATIONCODELABEL,
      required: true,
    },
  },
  EMPLOYMENT_AND_INCOME: {
    ssn: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.SSN,
      required: true,
      equalLength: AppConstants.EMPLOYMENTANDINCOME.SSNLENGTH,
    },
    sin: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.SIN,
      required: true,
      pattern: validateSIN,
    },
    listOfCountries: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.LIST_OF_COUNTRIES,
      required: true,
    },
    taxrecidentconfirmation: {
      fieldName:
        STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.TAX_RESIDENT_CONFIRMATION,
      fieldType: "select",
      required: true,
    },
    taxrecidentofothercountryconfirmation: {
      fieldName:
        STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR
          .TAX_RESIDENT_OF_OTHER_COUNTRY_CONFIRMATION,
      fieldType: "select",
      required: true,
    },
    country: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.COUNTRY,
      fieldType: "select",
      required: true,
    },
    taxidentificatinnumber: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.TAX_IDENTITY_NO,
      required: true,
    },
    citizenshipconfirmation: {
      fieldName:
        STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR
          .RECIDENT_OF_COUNTRY_IN_SELECTION_CONFIRMATION,
      fieldType: "select",
      required: true,
    },
    employmentStatus: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.EMPLOYMENT_STATUS,
      fieldType: "select",
      required: true,
    },
    occupationCode: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.JOB_TITLE,
      required: true,
    },
    jobTitle: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.JOB_TITLE,
      required: true,
    },
    employerName: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.EMPLOYER_NAME,
      required: true,
    },
    employerContactNumber: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.EMPLOYER_CONTACT_NO,
      pattern: AppConstants.REGISTRATION.MOBILENOPATTERN,
    },
    monthlyGrossIncome: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.MONTHLY_GROSS_INCOME,
      pattern: validateAmount,
    },
    sourceOfIncome: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.SOURCE_OF_INCOME,
      fieldType: "select",
      required: true,
    },
    ADDRESS1: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.ADDRESS1,
      required: true,
    },
  },
  TAX_INFO: {
    ssn: {
      fieldName: STRINGS.TAX_INFO.FORM.ERROR.SSN,
      required: true,
      maxLength: AppConstants.TAX_INFO.SSN_LENGTH,
    },
    ssn_required: {
      fieldName: STRINGS.TAX_INFO.FORM.ERROR.SSN,
      required: true,
    },
    ssn_other: {
      fieldName: STRINGS.TAX_INFO.FORM.ERROR.SSN,
      required: true,
      maxLength: AppConstants.TAX_INFO.SSN_OTHER_LENGTH,
    },
    ssn_usa: {
      fieldName: STRINGS.TAX_INFO.FORM.ERROR.SSN,
      required: true,
      equalLength: AppConstants.TAX_INFO.SSN_USA_LENGTH,
    },
    sin: {
      fieldName: STRINGS.TAX_INFO.FORM.ERROR.SIN,
      required: true,
      pattern: validateSIN,
    },
    listOfCountries: {
      fieldName: STRINGS.TAX_INFO.FORM.ERROR.LIST_OF_COUNTRIES,
      required: true,
    },
    taxResidentConfirmation: {
      fieldName: STRINGS.TAX_INFO.FORM.ERROR.TAX_RESIDENT_CONFIRMATION,
      fieldType: "select",
      required: true,
    },
    taxResidentOfOtherCountryConfirmation: {
      fieldName:
        STRINGS.TAX_INFO.FORM.ERROR.TAX_RESIDENT_OF_OTHER_COUNTRY_CONFIRMATION,
      fieldType: "select",
      required: true,
    },
    country: {
      fieldName: STRINGS.TAX_INFO.FORM.ERROR.COUNTRY,
      fieldType: "select",
      required: true,
    },
    taxIdentificationNumber: {
      fieldName: STRINGS.TAX_INFO.FORM.ERROR.TAX_IDENTITY_NO,
      required: true,
    },
    citizenshipConfirmation: {
      fieldName:
        STRINGS.TAX_INFO.FORM.ERROR
          .RECIDENT_OF_COUNTRY_IN_SELECTION_CONFIRMATION,
      fieldType: "select",
      required: true,
    },
  },
  GIC_INFO: {
    amount: {
      fieldName: STRINGS.GIC_INFO.FORM.ERROR.AMOUNT,
      amount: true,
      required: true,
    },
    intendedUse: {
      fieldName: STRINGS.GIC_INFO.FORM.ERROR.INTENDED_USE,
      fieldType: "select",
      required: true,
    },
  },
  TERMS_AND_CONDITIONS: {
    tearmAndCondition: {
      fieldName: STRINGS.MEMBERCREATION.TERMS_ERROR_TEXT,
      fieldType: "checkbox",
      required: true,
    },
  },

  GETTING_STARTED: {
    coapplicantName: {
      fieldName: STRINGS.GETTING_STARTED.ERROR.NAME_ERROR,
      required: true,
    },
    canadianResidencyStatus: {
      fieldName: STRINGS.GETTING_STARTED.ERROR.RESIDENT_STATUS,
      fieldType: "select",
    },
    confirmJointAccount: {
      fieldName: STRINGS.GETTING_STARTED.ERROR.CONFIRM_JOINT_ACCOUNT,
      fieldType: "select",
      required: true,
    },
    selectBranch: {
      fieldName: STRINGS.GETTING_STARTED.ERROR.SELECT_BRANCH,
      fieldType: "select",
      required: true,
    },
    selectCreditRequired: {
      fieldName: STRINGS.GETTING_STARTED.ERROR.SELECT_CREDIT_REQUIRED,
      fieldType: "select",
      required: true,
    },
    selectAccountStatementRequired: {
      fieldName:
        STRINGS.GETTING_STARTED.ERROR
          .SELECT_CHOICE_TO_RECIVE_ACCOUNT_STATEMENT_REQUIRED,
      fieldType: "select",
      required: true,
    },
    selectReasonRequired: {
      fieldName: STRINGS.GETTING_STARTED.ERROR.SELECT_REASON_REQUIRED,
      fieldType: "select",
      required: true,
    },
    overdraftProtectionRequired: {
      fieldName: STRINGS.GETTING_STARTED.ERROR.OVERDRAFT_PROTECTION_REQUIRED,
      fieldType: "select",
      required: true,
    },
  },
  COMPLIANCE: {
    isThirdPartyAccount: {
      fieldName: STRINGS.COMPLIANCE.FORM.THIRDPARTY_ACCOUNT_ERROR,
      fieldType: "select",
      required: true,
    },
    thirdPartyName: {
      fieldName: STRINGS.COMPLIANCE.FORM.THIRDPARTY_NAME_ERROR,
      required: true,
    },
    indicateName: {
      fieldName: STRINGS.COMPLIANCE.FORM.PERSON_NAME_ERROR,
      fieldType: "textbox",
      required: true,
    },
    selectAccountPurpose: {
      fieldName: STRINGS.COMPLIANCE.FORM.ACCOUNT_PURPOSE_ERROR,
      fieldType: "select",
      required: true,
    },
    RELATIONSHIP: {
      fieldName: STRINGS.COMPLIANCE.FORM.ERROR.RELATIONSHIP,
      fieldType: "select",
      required: true,
    },
    MEMBER_TYPE: {
      fieldName: STRINGS.COMPLIANCE.FORM.ERROR.MEMBER_TYPE,
      required: true,
    },
    OFFICE_HELD: {
      fieldName: STRINGS.COMPLIANCE.FORM.ERROR.OFFICE_HELD,
      required: true,
    },
    JURISDICTION: {
      fieldName: STRINGS.COMPLIANCE.FORM.ERROR.JURISDICTION,
      required: true,
    },
    SOURCE_OF_FUNDS: {
      fieldName: STRINGS.COMPLIANCE.FORM.ERROR.SOURCE_OF_FUNDS,
      required: true,
    },
  },
  ABOUTYOU: {
    ID: {
      fieldName: STRINGS.ABOUTYOU.PARTONE.IDERROR,
      fieldType: "select",
      required: true,
    },
    IDFRONT: {
      fieldName: STRINGS.ABOUTYOU.PARTTWO.IDFRONTLABEL,
      required: true,
    },
    IDBACK: {
      fieldName: STRINGS.ABOUTYOU.PARTTHREE.IDBACKLABEL,
      required: true,
    },
    SELFIE: {
      fieldName: STRINGS.ABOUTYOU.PARTFOUR.SELFIELABEL,
      required: true,
    },
    FIRSTNAME: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.FIRSTNAMELABEL,
      required: true,
      pattern: AppConstants.FIRSTNAMEPATTERN,
    },
    LASTNAME: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.LASTNAMELABEL,
      required: true,
      pattern: AppConstants.LASTNAMEPATTERN,
    },
    DOB: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.DOBLABEL,
      required: true,
      pattern: AppConstants.ABOUT_YOU.DOBPATTERN,
    },
    ADDRESS: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS1,
      required: true,
    },
    STREET_NO: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.STREET_NO,
      required: true,
    },
    STREET_NAME: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.STREET_NAME,
      required: true,
    },
    CITY: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY,
      required: true,
    },
    PROVINCE: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE,
      fieldType: "select",
      required: true,
    },
    CA_POSTALCODE: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE,
      required: true,
      pattern: AppConstants.ABOUT_YOU.CA_POSTALPATTERN,
    },
    US_POSTALCODE: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE,
      required: true,
      pattern: AppConstants.ABOUT_YOU.US_POSTALPATTERN,
    },
    SCANCONFIRMATION: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.SCANLABEL,
      fieldType: "select",
      required: true,
    },
    ADDRESSCONFIRMATION: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESSCONFIRMLABEL,
      fieldType: "select",
      required: true,
    },
    COUNTRY: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.COUNTRY,
      required: true,
    },
  },

  ACCOUNT_INFORMATION: {
    INTENDEDUSE: {
      fieldName: STRINGS.ACCOUNT_INFORMATION.ERROR.SELECT_INTENDED_USE,
      fieldType: "select",
      required: true,
    },
  },
  OVERDRAFT_PROTECTION: {
    overdraftProtection: {
      fieldName: STRINGS.GETTING_STARTED.ERROR.OVERDRAFT_PROTECTION_REQUIRED,
      fieldType: "select",
      required: true,
    },
  },
  BENEFICIARIES: {
    BENEFICIARY: {
      fieldName: STRINGS.BENEFICIARIES.ERROR.BENEFICIARY_ERROR,
      fieldType: "select",
      required: true,
    },
    FIRSTNAME: {
      fieldName: STRINGS.BENEFICIARIES.FORM.FIRSTNAMELABEL,
      required: true,
      pattern: AppConstants.FIRSTNAMEPATTERN,
    },
    LASTNAME: {
      fieldName: STRINGS.BENEFICIARIES.FORM.LASTNAMELABEL,
      required: true,
      pattern: AppConstants.LASTNAMEPATTERN,
    },
    DOB: {
      fieldName: STRINGS.BENEFICIARIES.FORM.DOBLABEL,
      required: true,
      pattern: AppConstants.ABOUT_YOU.DOBPATTERN,
    },
    RELATION: {
      fieldName: STRINGS.BENEFICIARIES.FORM.RELATIONSHIP,
      fieldType: "select",
      required: true,
    },
    BENEFICIARY_TYPE: {
      fieldName: STRINGS.BENEFICIARIES.FORM.BENEFICIARY_TYPE,
      fieldType: "select",
      required: true,
    },
    PERCENTAGE: {
      fieldName: STRINGS.BENEFICIARIES.FORM.PERCENTAGE,
      amount: true,
    },
    ADDRESS: {
      fieldName: STRINGS.BENEFICIARIES.FORM.ADDRESS1,
      required: true,
    },
    CITY: {
      fieldName: STRINGS.BENEFICIARIES.FORM.CITY,
      required: true,
    },
    PROVINCE: {
      fieldName: STRINGS.BENEFICIARIES.FORM.PROVINCE,
      fieldType: "select",
      required: true,
    },
    CA_POSTALCODE: {
      fieldName: STRINGS.BENEFICIARIES.FORM.POSTALCODE,
      required: true,
      pattern: AppConstants.ABOUT_YOU.CA_POSTALPATTERN,
    },
    US_POSTALCODE: {
      fieldName: STRINGS.BENEFICIARIES.FORM.POSTALCODE,
      required: true,
      pattern: AppConstants.ABOUT_YOU.US_POSTALPATTERN,
    },
    MOBILE_NO: {
      fieldName: STRINGS.BENEFICIARIES.FORM.MOBILENOLABEL,
      required: true,
      pattern: AppConstants.REGISTRATION.MOBILENOPATTERN,
    },
    COUNTRY: {
      fieldName: STRINGS.BENEFICIARIES.FORM.COUNTRY,
      fieldType: "select",
      required: true,
    },
    SIN: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.SIN,
      required: true,
      pattern: validateSIN,
    },
  },
  JOINT_APPLICANT: {
    EMAIL: {
      fieldName: STRINGS.JOINT_APPLICANT.ERROR.EMAIL_ERROR,
      required: true,
      pattern: AppConstants.REGISTRATION.EMAILPATTERN,
    },
    MOBILE: {
      fieldName: STRINGS.JOINT_APPLICANT.ERROR.MOBILE_ERROR,
      required: true,
    },
    TYPE: {
      fieldName: STRINGS.JOINT_APPLICANT.ERROR.TYPE_REQUIRED,
      fieldType: "select",
      required: true,
    },
    OWNERSHIP: {
      fieldName: STRINGS.JOINT_APPLICANT.ERROR.OWNERSHIP_REQUIRED,
      fieldType: "select",
      required: true,
    },
    SURVIVORSHIP: {
      fieldName: STRINGS.JOINT_APPLICANT.ERROR.SURVIVORSHIP_REQUIRED,
      fieldType: "select",
      required: true,
    },
  },
  LOANS: {
    DETAIL: {
      MORTGAGE_PROPERTY: {
        fieldName: STRINGS.LOAN_DETAILS.FORM.MORTGAGE.ERROR.VALUE_REQUIRED,
        amount: true,
      },
      VEHICLE: {
        fieldName: STRINGS.LOAN_DETAILS.FORM.AUTO.ERROR.VEHICLE_PRICE_REQUIRED,
        amount: true,
      },
      PROPERTY: {
        fieldName:
          STRINGS.LOAN_DETAILS.FORM.HELOC.ERROR.ASSESSED_VALUE_REQUIRED,
        amount: true,
      },
      MORTAGE_BALANCE: {
        fieldName:
          STRINGS.LOAN_DETAILS.FORM.HELOC.ERROR.MORTGAGE_BALANCE_REQUIRED,
        amount: true,
      },
      HELOC_AMOUNT: {
        fieldName: STRINGS.LOAN_DETAILS.FORM.HELOC.ERROR.HELOC_AMOUNT_REQUIRED,
        amount: true,
      },
    },
    INCOME: {
      INCOME_TYPE: {
        fieldName: STRINGS.INCOME.ERROR.INCOME_TYPE_REQUIRED,
        fieldType: "select",
        required: true,
      },
      DESCRIPTION: {
        fieldName: STRINGS.INCOME.ERROR.DESCRIPTION_REQUIRED,
        required: true,
      },
      MONTHLY_AMOUNT: {
        fieldName: STRINGS.INCOME.ERROR.MONTHLY_AMOUNT_REQUIRED,
        required: true,
      },
    },
    EXPENSE: {
      EXPENSE_TYPE: {
        fieldName: STRINGS.EXPENSE.ERROR.EXPENSE_TYPE_REQUIRED,
        fieldType: "select",
        required: true,
      },
      DESCRIPTION: {
        fieldName: STRINGS.EXPENSE.ERROR.DESCRIPTION_REQUIRED,
        required: true,
      },
      MONTHLY_AMOUNT: {
        fieldName: STRINGS.EXPENSE.ERROR.MONTHLY_AMOUNT_REQUIRED,
        required: true,
      },
    },
    VEHICLE: {
      YEAR: {
        fieldName: STRINGS.SECURITY_VEHICLE.ERROR.YEAR_REQUIRED,
        fieldType: "select",
        required: true,
      },
      MAKE: {
        fieldName: STRINGS.SECURITY_VEHICLE.ERROR.MAKE_REQUIRED,
        required: true,
      },
      MODEL: {
        fieldName: STRINGS.SECURITY_VEHICLE.ERROR.MAKE_REQUIRED,
        required: true,
      },
      VIN: {
        fieldName: STRINGS.SECURITY_VEHICLE.ERROR.VIN_REQUIRED,
        required: true,
      },
    },
    ASSET_PROPERTY: {
      STREET_NO: {
        fieldName: STRINGS.ASSET_PROPERTY.ERROR.STREET_NO_REQUIRED,
        required: true,
      },
      STREET_NAME: {
        fieldName: STRINGS.ASSET_PROPERTY.ERROR.STREET_NAME_REQUIRED,
        required: true,
      },
      CITY: {
        fieldName: STRINGS.ASSET_PROPERTY.ERROR.CITY_REQUIRED,
        required: true,
      },
      PROVINCE: {
        fieldName: STRINGS.ASSET_PROPERTY.ERROR.PROVINCE_REQUIRED,
        required: true,
      },
      POSTAL: {
        fieldName: STRINGS.ASSET_PROPERTY.ERROR.POSTAL_REQUIRED,
        required: true,
      },
      TENURE: {
        fieldName: STRINGS.ASSET_PROPERTY.ERROR.TENURE_REQUIRED,
        required: true,
      },
      OCCUPANCY: {
        fieldName: STRINGS.ASSET_PROPERTY.ERROR.OCCUPANCY_REQUIRED,
        required: true,
      },
    },
  },
  ASSET: {
    ASSET_TYPE: {
      fieldName: STRINGS.ASSET.ERROR.ASSET_TYPE_REQUIRED,
      fieldType: "select",
      required: true,
    },
    DESCRIPTION: {
      fieldName: STRINGS.ASSET.ERROR.DESCRIPTION_REQUIRED,
      required: true,
    },
    VALUE: {
      fieldName: STRINGS.ASSET.ERROR.VALUE_REQUIRED,
      required: true,
    },
    INSTITUTION: {
      fieldName: STRINGS.ASSET.ERROR.ORGANIZATION_REQUIRED,
      fieldType: "select",
      required: true,
    },
  },
  LIABILITIES: {
    SUB_TYPE: {
      fieldName: STRINGS.LIABILITIES.ERROR.SUB_TYPE_REQUIRED,
      fieldType: "select",
      required: true,
    },
    DESCRIPTION: {
      fieldName: STRINGS.LIABILITIES.ERROR.DESCRIPTION_REQUIRED,
      required: true,
    },
    VALUE: {
      fieldName: STRINGS.LIABILITIES.ERROR.VALUE_REQUIRED,
      required: true,
    },
    INSTITUTION: {
      fieldName: STRINGS.LIABILITIES.ERROR.ORGANIZATION_REQUIRED,
      fieldType: "select",
      required: true,
    },
    SECURED_TYPE: {
      fieldName: STRINGS.LIABILITIES.ERROR.SECURED_TYPE_REQUIRED,
      fieldType: "select",
      required: true,
    },
    ADJUSTMENT: {
      fieldName: STRINGS.LIABILITIES.ERROR.ADJUSTMENT_REQUIRED,
      fieldType: "select",
      required: true,
    },
    LIMIT: {
      fieldName: STRINGS.LIABILITIES.ERROR.LIMIT_REQUIRED,
      required: true,
    },
    AVG_PAYMENT: {
      fieldName: STRINGS.LIABILITIES.ERROR.AVG_PAYMENT_REQUIRED,
      required: true,
    },
    RATE: {
      fieldName: STRINGS.LIABILITIES.ERROR.RATE_REQUIRED,
      required: true,
    },
    PAYMENT_TYPE: {
      fieldName: STRINGS.LIABILITIES.ERROR.PAYMENT_TYPE_REQUIRED,
      fieldType: "select",
      required: true,
    },
    LOAN_FOR_DEBT_AMOUNT: {
      fieldName: STRINGS.LIABILITIES.ERROR.LOAN_FOR_DEBT_AMOUNT_REQUIRED,
      required: true,
    },
  },

  BUSINESS_TYPE: {
    BUSINESS_TYPE: {
      fieldName: STRINGS.BUSINESS_TYPE.ERROR.TYPE_REQUIRED,
      required: true,
      fieldType: "select",
    },
  },
  BUSINESS_ELIGIBILITY: {
    IS_CANNABIS_OR_MONEY: {
      fieldName:
        STRINGS.BUSINESS_ELIGIBILITY.ERROR.IS_CANNABIS_OR_MONEY_REQUIRED,
      required: true,
      fieldType: "select",
    },
    IS_FINANCIAL_INSTITUTION: {
      fieldName:
        STRINGS.BUSINESS_ELIGIBILITY.ERROR.IS_FINANCIAL_INSTITUTION_REQUIRED,
      required: true,
      fieldType: "select",
    },
  },
  BUSINESS_START: {
    REASON_OPEN_ACCOUNT: {
      fieldName:
        STRINGS.BUSINESS_START.ERROR.DECISION_TO_OPEN_AN_ACCOUNT_REQUIRED,
      required: true,
      fieldType: "select",
    },
    RELATIONSHIP_BUSINESS: {
      fieldName: STRINGS.BUSINESS_START.ERROR.RELATIONSHIP_BUSINESS_REQUIRED,
      required: true,
      fieldType: "select",
    },
    BUSINESS_BRANCH: {
      fieldName: STRINGS.BUSINESS_START.ERROR.BRANCH_REQUIRED,
      required: true,
      fieldType: "select",
    },
    ACCOUNT_STATEMENT_TYPE: {
      fieldName:
        STRINGS.BUSINESS_START.ERROR.RECEIVE_ACCOUNT_STATEMENTS_REQUIRED,
      required: true,
      fieldType: "select",
    },
  },
  BUSINESS_LOOKUP: {
    BUSINESS_LOOKUP: {
      fieldName: STRINGS.BUSINESS_LOOKUP.ERROR.BUSINESS_LOOKUP_REQUIRED,
      required: true,
      fieldType: "select",
    },
    LEGAL_BUSINESS_NAME: {
      fieldName: STRINGS.BUSINESS_LOOKUP.ERROR.LEGAL_BUSINESS_NAME_REQUIRED,
      required: true,
    },
    BUSINESS_NUMBER: {
      fieldName: STRINGS.BUSINESS_LOOKUP.ERROR.BUSINESS_NUMBER_REQUIRED,
      required: true,
      minLength: AppConstants.BUSINESS_LOOKUP.BUSINESS_NUMBER_LENGTH,
    },
    BUSINESS_JURISDICTION: {
      fieldName: STRINGS.BUSINESS_LOOKUP.ERROR.BUSINESS_JURISDICTION,
      required: true,
      fieldType: "select",
    },
  },
  BUSINESS_INFO_ADDRESS: {
    STREET_NAME: {
      fieldName: STRINGS.BUSINESS_INFO_ADDRESS.ERROR.STREET_NAME_REQUIRED,
      required: true,
    },
    STREET_NUMBER: {
      fieldName: STRINGS.BUSINESS_INFO_ADDRESS.ERROR.STREET_NUMBER_REQUIRED,
      required: true,
    },
    SUITE_NO: {
      fieldName: STRINGS.BUSINESS_INFO_MAIL.ERROR.SUITE_NO_REQUIRED,
      required: true,
    },
    CITY: {
      fieldName: STRINGS.BUSINESS_INFO_ADDRESS.ERROR.CITY_REQUIRED,
      required: true,
    },
    PROVINCE: {
      fieldName: STRINGS.BUSINESS_INFO_ADDRESS.ERROR.PROVINCE_REQUIRED,
      required: true,
      fieldType: "select",
    },
    POSTAL_CODE: {
      fieldName: STRINGS.BUSINESS_INFO_ADDRESS.ERROR.POSTAL_CODE_REQUIRED,
      required: true,
    },
  },
  BUSINESS_INFO_MAIL: {
    STREET_NUMBER: {
      fieldName: STRINGS.BUSINESS_INFO_MAIL.ERROR.STREET_NUMBER_REQUIRED,
      required: true,
    },
    STREET_NAME: {
      fieldName: STRINGS.BUSINESS_INFO_MAIL.ERROR.STREET_NAME_REQUIRED,
      required: true,
    },
    SUITE_NO: {
      fieldName: STRINGS.BUSINESS_INFO_MAIL.ERROR.SUITE_NO_REQUIRED,
      required: true,
    },
    CITY: {
      fieldName: STRINGS.BUSINESS_INFO_MAIL.ERROR.CITY_REQUIRED,
      required: true,
    },
    PROVINCE: {
      fieldName: STRINGS.BUSINESS_INFO_MAIL.ERROR.PROVINCE_REQUIRED,
      required: true,
      fieldType: "select",
    },
    POSTAL_CODE: {
      fieldName: STRINGS.BUSINESS_INFO_MAIL.ERROR.POSTAL_CODE_REQUIRED,
      required: true,
    },
  },
  BUSINESS_CONTACT: {
    EMAIL: {
      fieldName: STRINGS.BUSINESS_CONTACT.FORM.EMAIL,
      required: true,
      pattern: AppConstants.REGISTRATION.EMAILPATTERN,
    },
    BUSINESS_PHONE_NO: {
      fieldName: STRINGS.BUSINESS_CONTACT.FORM.BUSINESS_PHONE_NO,
      required: true,
    },
  },

  BUSINESS_OPERATIONS: {
    BUSINESS_COUNTRY_INCORPORATION: {
      fieldName: STRINGS.BUSINESS_OPERATIONS.ERROR.COUNTRY_REQUIRED,
      required: true,
      fieldType: "select",
    },
    BUSINESS_PHYSICAL_LOCATION: {
      fieldName: STRINGS.BUSINESS_OPERATIONS.ERROR.COUNTRY_REQUIRED,
      required: true,
      fieldType: "select",
    },
    BUSINESS_TRANSACT: {
      fieldName: STRINGS.BUSINESS_OPERATIONS.ERROR.COUNTRY_REQUIRED,
      required: true,
      fieldType: "select",
    },
    BUSINESS_SECTOR: {
      fieldName: STRINGS.BUSINESS_OPERATIONS.ERROR.SECTOR_REQUIRED,
      required: true,
      fieldType: "select",
    },
    BUSINESS_SUB_SECTOR: {
      fieldName: STRINGS.BUSINESS_OPERATIONS.ERROR.SUB_SECTOR_REQUIRED,
      required: true,
      fieldType: "select",
    },
    BUSINESS_GROUP: {
      fieldName: STRINGS.BUSINESS_OPERATIONS.ERROR.GROUP_REQUIRED,
      required: true,
      fieldType: "select",
    },
    BUSINESS_INDUSTRY: {
      fieldName: STRINGS.BUSINESS_OPERATIONS.ERROR.INDUSTRY_REQUIRED,
      required: true,
      fieldType: "select",
    },
    BUSINESS_INDUSTRY_DESCRIPTION: {
      fieldName: STRINGS.BUSINESS_OPERATIONS.ERROR.INDUSTRY_DESCRIBE_REQUIRED,
      required: true,
      fieldType: "select",
    },
    BUSINESS_ENTITY: {
      fieldName: STRINGS.BUSINESS_OPERATIONS.ERROR.ENTITY_REQUIRED,
      required: true,
      fieldType: "select",
    },
  },
  BUSINESS_TAX: {
    NOT_FOR_PROFIT: {
      fieldName: STRINGS.BUSINESS_TAX.ERROR.NOT_FOR_PROFIT_REQUIRED,
      required: true,
      fieldType: "select",
    },
    TAX_COUNTRY_OTHERS: {
      fieldName: STRINGS.BUSINESS_TAX.ERROR.COUNTRY_REQUIRED,
      required: true,
      fieldType: "select",
    },
    TIN_OTHERS: {
      fieldName: STRINGS.BUSINESS_TAX.ERROR.TIN_REQUIRED,
      required: true,
    },
    TIN_USA: {
      fieldName: STRINGS.BUSINESS_TAX.ERROR.TIN_USA_REQUIRED,
      required: true,
      pattern: AppConstants.BUSINESS_TAX.US_TIN_PATTERN,
    },
  },
  BUSINESS_COMPLIANCE: {
    PURPOSE: {
      fieldName: STRINGS.BUSINESS_COMPLIANCE.ERROR.INTENDED_PURPOSE_REQUIRED,
      required: true,
      fieldType: "select",
    },
  },
  BUSINESS_OVERDRAFT: {
    OVERDRAFT: {
      fieldName: STRINGS.BUSINESS_OVERDRAFT.ERROR.OVERDRAFT_REQUIRED,
      required: true,
      fieldType: "select",
    },
  },
  BUSINESS_CHEQUE: {
    CHEQUE: {
      fieldName: STRINGS.BUSINESS_CHEQUE.ERROR.CHEQUE_REQUIRED,
      required: true,
      fieldType: "select",
    },
  },
  BUSINESS_DEBIT: {
    DEBIT: {
      fieldName: STRINGS.BUSINESS_DEBIT.ERROR.ACCOUNT_DEBIT_CARDS_REQUIRED,
      required: true,
      fieldType: "select",
    },
  },
  BUSINESS_ONLINE: {
    ONLINE_BANKING: {
      fieldName: STRINGS.BUSINESS_ONLINE.ERROR.ACCOUNT_ONLINE_BANKING_REQUIRED,
      required: true,
      fieldType: "select",
    },
  },
  BUSINESS_EMPLOYMENT: {
    EMPLOYMENT_STATUS: {
      fieldName: STRINGS.BUSINESS_EMPLOYMENT.ERROR.EMPLOYMENT_STATUS_REQUIRED,
      required: true,
      fieldType: "select",
    },
    POSITION: {
      fieldName: STRINGS.BUSINESS_EMPLOYMENT.ERROR.POSITION_REQUIRED,
      required: true,
    },
    EMPLOYER_NAME: {
      fieldName: STRINGS.BUSINESS_EMPLOYMENT.ERROR.EMPLOYER_NAME_REQUIRED,
      required: true,
    },
  },
  BUSINESS_MEMBER: {
    FIRSTNAME: {
      fieldName: STRINGS.BUSINESS_MEMBER.FORM.FIRSTNAME_LABEL,
      required: true,
      pattern: AppConstants.FIRSTNAMEPATTERN,
    },
    LASTNAME: {
      fieldName: STRINGS.BUSINESS_MEMBER.FORM.LASTNAME_LABEL,
      required: true,
      pattern: AppConstants.LASTNAMEPATTERN,
    },
    EMAIL: {
      fieldName: STRINGS.BUSINESS_MEMBER.FORM.EMAIL_LABEL,
      required: true,
      pattern: AppConstants.REGISTRATION.EMAILPATTERN,
    },
    MOBILE_NO: {
      fieldName: STRINGS.BUSINESS_MEMBER.FORM.MOBILE_NO_LABEL,
      required: true,
      pattern: AppConstants.REGISTRATION.MOBILENOPATTERN,
    },
  },
  QUESTIONNAIRE: {
    SELECT_FIELD: {
      fieldName: STRINGS.QUESTIONNAIRE.ERROR.SELECT_REQUIRED,
      fieldType: "select",
      required: true,
    },
    TEXT_FIELD: {
      fieldName: STRINGS.QUESTIONNAIRE.ERROR.TEXT_REQUIRED,
      required: true,
    },
  },
  MEMBERSHIP: {
    MEMBERSHIP: {
      fieldName: STRINGS.MEMBERSHIP.ERROR.SELECT_MEMBERSHIP,
      fieldType: "select",
      required: true,
    },
  },
};

export default VALIDATIONSLIST;
